/*! nouislider - 9.1.0 - 2016-12-10 16:00:32 */

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
}
.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 8px;
}
.noUi-horizontal .noUi-handle {
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 241, 242, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(241, 241, 242, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 241, 242, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 241, 242, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 241, 242, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 241, 242, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$white-color', endColorstr='#f1f1f2',GradientType=0 ); /* IE6-9 */

  border-radius: 50%;
  box-shadow: 0 1px 1px $white-color inset, 0 1px 2px rgba(0, 0, 0, 0.4);
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: -10px;
  margin-top: -4px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.noUi-target {
  background-color: #e5e5e5;
  border-radius: 3px;
  // border: 1px solid #D3D3D3;
  // box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connect {
  background: #3fb8af;
  // box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
  border-radius: 3px;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  // border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

/* Handle stripes;
 */
// .noUi-handle:before,
// .noUi-handle:after {
// 	content: "";
// 	display: block;
// 	position: absolute;
// 	height: 14px;
// 	width: 1px;
// 	background: #E8E7E6;
// 	left: 14px;
// 	top: 6px;
// }
// .noUi-handle:after {
// 	left: 17px;
// }
// .noUi-vertical .noUi-handle:before,
// .noUi-vertical .noUi-handle:after {
// 	width: 14px;
// 	height: 1px;
// 	left: 6px;
// 	top: 14px;
// }
// .noUi-vertical .noUi-handle:after {
// 	top: 17px;
// }

/* Disabled state;
 */

[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.slider {
  &.slider-info {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $info-color;
    }

    .noUi-handle {
      border-color: $info-color;
    }
  }
  &.slider-success {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $success-color;
    }

    .noUi-handle {
      border-color: $success-color;
    }
  }
  &.slider-warning {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $warning-color;
    }

    .noUi-handle {
      border-color: $warning-color;
    }
  }
  &.slider-danger {
    & .noUi-connect,
    &.noUi-connect {
      background-color: $danger-color;
    }

    .noUi-handle {
      border-color: $danger-color;
    }
  }
}
